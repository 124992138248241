.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-float infinite 3s ease-in-out;
  }
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.App-link {
  color: rgb(112, 76, 182);
}

@keyframes App-logo-float {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(0px);
  }
}

iframe {
  width: 100%;
  height: 245px;
  font-family: 'PoppinsVariableFont';
}


.divider-tt {
  display: flex;
  align-items: center;
  width: 100%;
  position: relative;
}

.divider-line {
  flex-grow: 1;
  height: 1px;
  background-color: #ccc;
}

.divider-text {
  background-color: #fff;
  padding: 0 10px;
  font-size: 14px;
  color: #888;
}