.datepicker-input-slot {
  .MuiInputBase-root {
       .Mui-disabled {
      color: #121926 !important;
      -webkit-text-fill-color: #121926 !important;
  }
}
}

.datepicker-input-slot1:disabled {
  color: gray; /* Change to the desired text color */
  font-weight: normal; /* Change to the desired font weight */
}